.App-mainBody {
  padding-top: 50px;
  padding-bottom: 50px;
}

.App-breadcrumbs {
  padding-top: 30px;
  padding-bottom: 20px;
}

.App-appName {
  margin-top: 5px !important;
}

.App-healthcare-now-logo img {
  height: 50px;
}

.App-candidateBadge {
  padding-left: 3px;
  height: 1.2em;
  display: inline-block;
}

.App-candidateBadge svg {
  height: 100%;
  vertical-align: bottom;
}

.App-complianceIcon {
  height: 1.5em;
  display: inline-block;
}

.App-complianceIcon svg {
  height: 100%;
}

.partyName {
  padding: 0.6em;
}

.candidateList {
  height: 100%;
  padding: 0.6em;
}

.candidateList.republican {
  background-color: #f7c0c2;
}

.candidateList.democrat {
  background-color: #dadef7;
}

.candidateList.other {
  background-color: #d9d7d7;
}

.candidateLink a {
  color: black;
}

.stateNavigationButton {
  margin-left: 1em;
}
